import React from 'react';
import { FooterIcon } from './FooterIcon';
import { FooterMenu } from './FooterMenu';
import { Logo } from './Logo';

export const FooterTop = () => {
  return (
    <>
     <div className='footer-top'>
        <Logo />
        <FooterMenu />
        <FooterIcon />
     </div>
    </>
  )
}
