import React from 'react';
import { AppImg } from './AppImg';
import { DownloadImg } from './DownloadImg';
import './Download.css';
import { PlayStore } from '../PlayStore/PlayStore';

export const Download = () => {
  return (
    <>
     <div className='download-sec-wrp' id='downloadSection' style={{ backgroundImage: 'url(../../images/download-bg.png)'}}>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='download-wrp'>
                        <DownloadImg />
                        <AppImg />
                        <PlayStore />
                    </div>
                </div>
            </div>
        </div>
     </div>
    </>
  )
}
