import React from 'react';
import { Link } from 'react-router-dom';
import './PlayStore.css';

export const PlayStore = () => {
  return (
    <>
     <div className='banner-btn'>
        <a href='https://play.google.com/store/apps/details?id=net.live.tech.torjoni'><img src='./images/store-button.png' alt=''/></a>
        <a href='https://apps.apple.com/gb/app/torjoni-browser/id6446063383'><img src='./images/store-button-apple.png' alt=''/></a>
     </div>
    </>
  )
}





