import React from 'react';
import { PlayStore } from '../PlayStore/PlayStore';
import './Banner.css';


export const Banner = () => {
  return (
    <>
     <div className='banner-sec-wrp' style={{ backgroundImage: 'url(../../images/banner-bg.png)'}}>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='banner-wrp'>
                        <div className='banner-img'>
                            {/* <img src='./images/mujib-banner-img.png' alt='banner images' /> */}
                            <img src='./images/without-head.png' alt='banner images' />
                        </div>
                        <div className='banner-dsc'>
                            <img src='./images/banner-logo.png' alt='Banner Logo'/>
                            <h2>দেশের প্রথম <span> নিরাপদ এবং <br></br> দ্রুতগতির </span> বাংলা ব্রাউজার</h2>
                            <span>বাংলাদেশি বাংলা ব্রাউজার</span>
                            <PlayStore />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </>
  )
}
