import React from 'react';
import { Link } from 'react-router-dom';

export const Logo = () => {
  return (
    <>
     <div className='ftr-col-1'>
        <div className='logo'>
            <Link to='/'>
              <img src='./images/logo.png' alt='Footer Logo' />
            </Link>
        </div>
        <div className='socail-icon'>
            <ul className='clearfix reset-list'>
                <li><Link to='#'><i className="ri-facebook-fill"></i></Link></li>
                <li><Link to='#'><i className="ri-instagram-line"></i></Link></li>
                <li><Link to='#'><i className="ri-twitter-fill"></i></Link></li>
            </ul>
        </div>
     </div>
    </>
  )
}
