import React from 'react';
import { Link } from 'react-router-dom';

export const FeaturedButton = () => {
  return (
    <>
     <div className='featured-button'>
        <ul className='clearfix reset-list'>
            <li>
                <Link to='#'>
                    <i><img src='./images/ad-block.png' alt='Icon'/></i>
                    বিজ্ঞাপন বন্ধ
                </Link>
            </li>
            <li>
                <Link to='#'>
                    <i><img src='./images/dark-mode.png' alt='Icon'/></i>
                    ডার্ক মোড
                </Link>
            </li>
            <li>
                <Link to='#'>
                    <i><img src='./images/ad-block-3.png' alt='Icon'/></i>
                    ডেটা সঞ্চয়
                </Link>
            </li>
            <li>
                <Link to='#'>
                    <i><img src='./images/bookmark.png' alt='Icon'/></i>
                    বুকমার্ক
                </Link>
            </li>
        </ul>
     </div>
    </>
  )
}
