import React from 'react';

export const FooterIcon = () => {
  return (
    <>
     <div className='footer-icon'>
        <img src='./images/footer-icon.png' alt='footer Icon' />
     </div>
    </>
  )
}
