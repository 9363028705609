import React from 'react'
import NoPageFoundImg from "../img/404.png"

function NoPageFound() {
  return (
    <div className="container mt-4 pageNotFound">
      <div className="row align-items-center">
        <div className="mx-auto">
            <div className="mt-3">
              <img
                  src={NoPageFoundImg}
                  alt="No Page Found"
                  width="600"
                  className="img-fluid"
                  data-app-dark-img="illustrations/page-misc-error-dark.png"
                  data-app-light-img="illustrations/page-misc-error-light.png"
              />
              <a href={'/'} className="btn btn-primary mt-4 back404">Back to Home</a>
            </div>
        </div>
      </div>
    </div>
  )
}

export default NoPageFound