import {
  BrowserRouter, Route,
  Switch
} from 'react-router-dom';
// We will create these two pages in a moment
import './App.css';
import NoPageFound from './pages/404';
import HomePage from './pages/HomePage';
import HomePageEn from './pages/HomePageEn';
import Privacy from './pages/privacy';
import FairUsage from './pages/fair-usage-policy';
import Disclamer from './pages/disclamer';
import Torjoni from './pages/torjoni';

function App() {
  
  return (
      <BrowserRouter>
          <div>
            <div className="content">
              <Switch>
                   {/* <Route exact path="/" component={HomePage} /> */}
                  <Route exact path="/" component={Torjoni} />
                  <Route exact path="/privacy" component={Privacy}/>
                  <Route exact path="/fair-usage-policy" component={FairUsage}/>
                  <Route exact path="/disclamer" component={Disclamer}/>
                  <Route exact path="/about" component={Torjoni}/>
                  <Route exact path="/landing" component={HomePage} />
                  <Route exact path="/landing/en" component={HomePageEn} />


                  <Route path="*" component={NoPageFound}/>
              </Switch>
            </div>
        </div>
      </BrowserRouter>
    
  );
}

export default App;
