import React from 'react';
import { Link } from 'react-scroll';
import './Footer.css';

export const FooterMenu = () => {
  return (
    <>
     <div className='footer-menu'>
        <ul className='clearfix reset-list'>
            {/* <li><Link activeClass='active' smooth spy to='aboutSection'>গোপনীয়তার নীতি</Link></li> */}
            <li><a href='/privacy' className='active'>গোপনীয়তার নীতি</a></li>
            <li><Link activeClass='active' smooth spy to='featuredSection'>তর্জনী সম্পর্কে</Link></li>
            <li><Link activeClass='active' smooth spy to='downloadSection'>ডাউনলোড</Link></li>
        </ul>
     </div>
    </>
  )
}
