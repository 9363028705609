import React from 'react';
import { FeaturedContent } from './FeaturedContent';
import { FeaturedImg } from './FeaturedImg';
import './Featured.css';

export const Featured = () => {
  return (
    <>
     <div className='featured-sec-wrp' id='featuredSection'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='featured-wrp'>
                        <FeaturedContent />
                        <FeaturedImg />
                    </div>
                </div>
            </div>
        </div>
     </div>
    </>
  )
}
