import React, { useState } from 'react';
import { Link } from 'react-scroll';
// import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <nav className='nav-menu'>
          <div className='hambar'>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-fade-text"
            aria-expanded={open}
          >
            <i className="ri-menu-line"></i>
          </Button>
          </div>
            <Fade in={open}>
            <ul className='clearfix reset-list' id="example-fade-text">
              {/* <li>
                <Link activeClass='active' smooth spy to='aboutSection'>তর্জনী কি এবং কেন?</Link>
              </li> */}
              <li>
                <Link activeClass='active' smooth spy to='featuredSection'>গুরুত্বপূর্ণ ফিচারস</Link>
              </li>
              <li>
                <Link activeClass='active' smooth spy to='downloadSection'>ডাউনলোড</Link>
              </li>
            </ul>
            </Fade>
      </nav>
    </>
  )
}
