import React from 'react';

export const DownloadImg = () => {
  return (
    <>
     <div className='dwn-img'>
        <img src='./images/iPhone-in-Hand-Mockup-2.png' alt='iPhone Hand'/>
     </div>
    </>
  )
}
