import React from 'react';

const Disclamer = () => {
  return (
    <>
    <div className='page-content-wrp'>
        <img src='./images/Fab.png' alt='' />
        <h3>Disclaimer</h3>
        <p>There is no commercial transaction related with the browser. Torjoni Browser is free for all customers. (গ্রাহকদের সাথে তর্জনী ব্রাউজারের কোন আর্থিক লেনদেন নেই। তর্জনী ব্রাউজারের সমস্ত গ্রাহক, সফটওয়্যারটি সবসময় বিনামূল্যে ব্যাবহার করতে পারবে।) </p>
        {/* <h3>ব্যবহারে পয়েন্ট ও উপহার নীতি</h3>
        <p>এখন ইন্টারনেট ব্রাউজ করেই পাচ্ছেন পয়েন্ট ও আকর্ষনীয় পুরষ্কার। দেখুন কিভাবে তুলে নিবেন আপনার উপহারঃ</p>
        <strong>কিভাবে পয়েন্ট পাবেনঃ</strong>
        <ul>
          <li>প্রতি ৬০ মিনিট অ্যাপ ব্যাবহারে- ৬০ পয়েন্ট</li>
          <li>স্থির বিজ্ঞাপন দেখলে – ১ পয়েন্ট</li>
          <li>ভিডিও বিজ্ঞাপন দেখলে – ৫ পয়েন্ট</li>
          <li>সাথে রয়েছে সাইন আপেই ৫০ পয়েন্ট বোনাস!</li>
        </ul>
        <strong>উপহারঃ</strong>
        <ul>
          <li>২০০০ পয়েন্টে – ৫০০ মেগাবাইট মোবাইল ডাটা</li>
          <li>৩০০০ পয়েন্টে – ১ গিগাবাইট মোবাইল ডাটা</li>
          <li>৫০০০ পয়েন্টে – ১০০ টাকার টকটাইম</li>
        </ul>
        <strong>কিভাবে উপহার পাবেনঃ</strong>
        <p>সাইন আপ করলেই দেখতে পাবেন পয়েন্ট টেবিল ও উপহার টেবিল। পর্যাপ্ত পয়েন্ট উঠলেই আপনি উপহার ক্লেইম করতে পারবেন। সেক্ষেত্রে উপহার টেবিলে কাঙ্ক্ষিত উপহারের পাশে উত্তোলন নামক বাটনটিতে ক্লিক করলেই পেয়ে যাবেন আপনার উপহার।</p>
        <p>বিঃদ্রঃ ক্লেইম করার এক সপ্তাহের মধ্যেই পেয়ে যাবেন আপনার উপহার।</p>
        <h3>Usage Point & Reward Policy</h3>
        <p>Now you can get points & rewards while browsing on the Internet. Here is how you can enjoy this:</p>
        <strong>HOW TO EARN POINTS:</strong>
        <ul>
          <li>Using the app for 60 minutes – 60 Points</li>
          <li>View Image Add – 1 Point</li>
          <li>View Video Add – 5 Points</li>
          <li>Moreover, you will get 50 <strong>BONUS</strong> Points if you just sign up!</li>
        </ul>
        <strong>REWARDS:</strong>
        <ul>
          <li>500 MB mobile data with 2000 Points</li>
          <li>1 GB mobile data with 3000 Points</li>
          <li>100 Taka talk time with 5000 Points</li>
        </ul>
        <strong>HOW TO CLAIM REWARDS:</strong>
        <p>After signing up, you will find the Point & Reward table. In Reward table, there is a button called “Withdraw”. If you have enough point to claim your reward, just tap on “Withdraw” button. And wait for your reward! It’s that easy.</p>
        <p><strong>NB:</strong> You will get your reward within 1 week after your withdrawal request.</p> */}
    </div>
    </>
  )
}

export default Disclamer
