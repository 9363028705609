import React from 'react';

export const AppImg = () => {
  return (
    <>
     <div className='app-img'>
        <img src='./images/app-img.png' alt='App Img' />
     </div>
    </>
  )
}
