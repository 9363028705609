import React from 'react';

export const FeaturedImg = () => {
  return (
    <>
     <div className='featured-img'>
        <img src='./images/featured-img.png' alt='Featured Images'/>
     </div>
    </>
  )
}
