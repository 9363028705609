import React from 'react';
import { FeaturedButton } from './FeaturedButton';

export const FeaturedContent = () => {
  return (
    <>
     <div className='featured-content'>
        <img src='./images/torjoni-icon.png' alt='Torjoni Logo' />
        <h3>গুরুত্বপূর্ণ ফিচারস</h3>
        <p>দুর্দান্ত গতি, অধিক নিরাপত্তা ও গোপনীয়তার নীতি, কাস্টমাইজশনের <br></br> সুবিধা সমূহ তজর্নী ব্রাউজারকে করে তুলেছে স্মার্ট ব্রাউজার।</p>
        <FeaturedButton />
     </div>
    </>
  )
}
