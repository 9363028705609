import React from 'react';

const FairUsage = () => {
  return (
    <>
    <div className='page-content-wrp'>
        <img src='./images/Fab.png' alt='' />
        <h3>Fair Usage Policy</h3>
        <p>তর্জনী ব্রাউজারে প্রদর্শিত সমস্ত কন্টেন্ট প্রকাশ্যে উপলভ্য এবং সম্পূর্ণ বিনামূল্যের। আমরা তর্জনী ব্রাউজারে কেবল কন্টেন্টগুলো সংগ্রহ এবং প্রদর্শন করছি যাতে এর ব্যবহারকারীরা সহজেই এক জায়গা থেকে কন্টেন্টগুলো উপভোগ করতে পারে। যদি কখনো কোন কপিরাইট লঙ্ঘন এর রিপোর্ট করা হয় তবে তর্জনী উল্লিখিত কন্টেন্ট বা পুরো অংশটি সরিয়ে ফেলবে। (All the contents showing in Torjoni Browser are publicly available and free of cost. We are just collecting and showcasing the contents in Torjoni browser to make it easier access to user. If any copyright infringement is reported, Torjoni will remove the reported content or segment.)  </p>
    </div>
    </>
  )
}

export default FairUsage
