import {React} from 'react';
import { About } from '../components/About/About';
import { Banner } from '../components/Banner/Banner';
import { Download } from '../components/Download/Download';
import { Featured } from '../components/Featured/Featured';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';


const Torjoni = () => {


  // const [darkMode, setDarkMode] = useState(false);

  return (
    <>
    <div>
        <div className='block-1280'>
        <Header />
        <Banner />
        {/* <About /> */}
        <Download />
        <Featured />
        <Footer />
      </div>
     </div>
    </>
  )
}

export default Torjoni
