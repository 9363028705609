import React from 'react';
import './Footer.css';
import { FooterTop } from './FooterTop';

export const Footer = () => {
  return (
    <>
     <div className='footer-sec-wrp'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                   <div className='footer-wrp'>
                      <FooterTop />
                    </div> 
                </div>
            </div>
        </div>
        <div className='footer-btm'>
            <ul className='clearfix reset-list'>
                <li>কপিরাইট © ২০২৩ - তর্জনী ব্রাউজার</li>
                <li>পাওয়ার্ড বাই - বাংলাদেশ কম্পিউটার কাউন্সিল (বিসিসি)</li>
            </ul>
        </div>
     </div>
    </>
  )
}
